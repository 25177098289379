
import { defineComponent } from "vue";
import { Editor, EditorContent } from "@tiptap/vue-3";
import Document from "@/components/inline-notes/extensions/document";

import Note from "@/components/inline-notes/extensions/note";
import Collection from "@/components/inline-notes/extensions/collection";

import Paragraph from "@/components/inline-notes/extensions/paragraph";
import Text from "@tiptap/extension-text";
export default defineComponent({
  name: "InlineNotes",
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null as any,
    };
  },
  created: function () {
    this.editor = new Editor({
      extensions: [Document, Collection, Note, Paragraph, Text],
      content: `
      <p>Testing notes...</p>
      `,
    });
  },
  methods: {
    wrapNote: function () {
      (this.editor as Editor).chain().wrapNote().focus().run();
    },
  },
});
